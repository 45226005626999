define(["handlebars"], function(Handlebars) { return Handlebars.template(function (Handlebars,depth0,helpers,partials,data,depth1) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, self=this, functionType="function", escapeExpression=this.escapeExpression;

function program1(depth0,data) {
  
  
  return "\n                selected\n              ";
  }

function program3(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n        <div class=\"review-pagination\">\n        ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.previousPage), {hash:{},inverse:self.program(6, program6, data),fn:self.program(4, program4, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n\n        <div class=\"page-number-container\">\n\n\n\n\n            <div class=\"page-number\n              ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageOneSelected), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            \"><a href=\"#\" id=\"pageOne\">\n              ";
  stack1 = ((stack1 = ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageBlockOne)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            </a></div>\n\n\n            <div class=\"page-number\n              ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageTwoSelected), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            \"><a href=\"#\" id=\"pageTwo\">\n              ";
  stack1 = ((stack1 = ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageBlockTwo)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            </a></div>\n\n          ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageThreeLink), {hash:{},inverse:self.noop,fn:self.program(8, program8, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n          ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageFourLink), {hash:{},inverse:self.noop,fn:self.program(11, program11, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n          ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageFiveLink), {hash:{},inverse:self.noop,fn:self.program(13, program13, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n          ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageElipsis), {hash:{},inverse:self.noop,fn:self.program(15, program15, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n        </div>\n\n        ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.nextPage), {hash:{},inverse:self.program(19, program19, data),fn:self.program(17, program17, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </div>\n      ";
  return buffer;
  }
function program4(depth0,data) {
  
  
  return "\n          <a href=\"#\" id=\"previous\">\n            <div id='prev-page' class=\"active\">\n              <\n            </div>\n          </a>\n        ";
  }

function program6(depth0,data) {
  
  
  return "\n          <div id='prev-page'>\n            <span class=\"disabled\"><</span>\n          </div>\n        ";
  }

function program8(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n\n              <div class=\"page-number\n                ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageThreeSelected), {hash:{},inverse:self.noop,fn:self.program(9, program9, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n              \"><a href=\"#\" id=\"pageThree\">\n                ";
  stack1 = ((stack1 = ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageBlockThree)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n              </a></div>\n\n          ";
  return buffer;
  }
function program9(depth0,data) {
  
  
  return "\n                  selected\n                ";
  }

function program11(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n\n              <div class=\"page-number\n                ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageFourSelected), {hash:{},inverse:self.noop,fn:self.program(9, program9, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n              \"><a href=\"#\" id=\"pageFour\">\n                ";
  stack1 = ((stack1 = ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageBlockFour)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                </a></div>\n\n          ";
  return buffer;
  }

function program13(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n\n              <div class=\"page-number\n                ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageFiveSelected), {hash:{},inverse:self.noop,fn:self.program(9, program9, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n              \"><a href=\"#\" id=\"pageFive\">\n                ";
  stack1 = ((stack1 = ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageBlockFive)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n              </a>  </div>\n\n          ";
  return buffer;
  }

function program15(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n            <div class=\"page-elipsis dots\">\n              ...\n            </div>\n\n              <div class=\"page-number\n                ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.pageUpperSelected), {hash:{},inverse:self.noop,fn:self.program(9, program9, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n              \"><a href=\"#\" id=\"pageUpper\">\n                "
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.upperPageLink)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n              </a>  </div>\n\n          ";
  return buffer;
  }

function program17(depth0,data) {
  
  
  return "\n        <a href=\"#\" id=\"next-button\">\n            <div id='next-page' class=\"active\">\n              >\n            </div>\n          </a>\n        ";
  }

function program19(depth0,data) {
  
  
  return "\n          <div id='next-page'>\n            <span class=\"disabled\">></span>\n          </div>\n        ";
  }

function program21(depth0,data,depth1,depth2) {
  
  var buffer = "", stack1, helper;
  buffer += "\n\n    <div class=\"review-block\" itemprop=\"reviews\" itemscope itemtype=\"http://schema.org/Review\">\n    <h3 class=\"product-review-title cf\" itemprop=\"name\">\n        ";
  if (helper = helpers.title) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.title); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n      </h3>\n\n\n      <div class=\"all-stars-holder\">\n        ";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth1 && depth1.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.overall), {hash:{},inverse:self.noop,fn:self.programWithDepth(22, program22, data, depth1),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        <div class=\"rating-holder\" itemprop=\"reviewRating\" itemscope itemtype=\"http://schema.org/Rating\">\n          <meta itemprop=\"worstRating\" content=\"0\" />\n          <meta itemprop=\"bestRating\" content=\"5\" />\n          <div class=\"rating\"></div>\n          <span class=\"rating-stars\" style=\"width:";
  if (helper = helpers.reviewScorePercentage) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.reviewScorePercentage); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "%\" itemprop=\"ratingValue\">\n            "
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = (depth2 && depth2.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.score)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n          </span>\n        </div>\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.addedCriteria), {hash:{},inverse:self.noop,fn:self.programWithDepth(24, program24, data, depth1),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n      </div>\n\n      <p itemprop=\"description\">\n        ";
  if (helper = helpers.content) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.content); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n      </p>\n\n      <p class=\"review-author\">\n        <meta itemprop=\"datePublished\" content=\"";
  if (helper = helpers.dateConvert) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.dateConvert); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" type=\"date\" dateStyle=\"MEDIUM\" pattern=\"yyyy-MM-dd\"/>\n        ";
  if (helper = helpers.created) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.created); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " ";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth1 && depth1.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.reviewByJoin)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        <span class=\"product-review-author\" itemscope itemprop=\"author\" itemtype=\"http://schema.org/Person\">\n          <span itemprop=\"name\">\n            ";
  if (helper = helpers.reviewer) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.reviewer); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + " ";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth1 && depth1.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.reviewByJoinDE)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n          </span>\n        </span>\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.allTimeTop), {hash:{},inverse:self.noop,fn:self.programWithDepth(31, program31, data, depth1),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        ";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.allTimeTop), {hash:{},inverse:self.noop,fn:self.programWithDepth(33, program33, data, depth1, depth2),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n      </p>\n\n      <div class=\"thumbs-up-down\">\n        <p>";
  if (helper = helpers.reviewHelpful) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.reviewHelpful); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</p>\n        <ul class=\"helpful\">\n          <a rel=\"nofollow\" class=\"yes-link\" href=\""
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = (depth1 && depth1.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.siteSecureURL)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = (depth1 && depth1.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.site)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "/helpfulReview.account?helpful=true&prodId=";
  if (helper = helpers.productId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.productId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "&reviewId=";
  if (helper = helpers.reviewId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.reviewId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n            <li class=\"review-yes\">\n              ";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth1 && depth1.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.reviewYes)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " <span class=\"review-number\">(";
  if (helper = helpers.helpfulVotes) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.helpfulVotes); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + ")</span>\n            </li>\n          </a>\n          <a rel=\"nofollow\" class=\"no-link\" href=\""
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = (depth1 && depth1.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.siteSecureURL)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = (depth1 && depth1.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.site)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "/helpfulReview.account?unhelpful=true&prodId=";
  if (helper = helpers.productId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.productId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "&reviewId=";
  if (helper = helpers.reviewId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.reviewId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n            <li class=\"review-no\">\n              ";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth1 && depth1.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.reviewNo)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " <span class=\"review-number\">(";
  if (helper = helpers.unhelpfulVotes) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.unhelpfulVotes); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + ")</span>\n            </li>\n          </a>\n        </ul>\n        <div class=\"report-abuse\">\n          <p>\n            <a rel=\"nofollow\" href=\""
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = (depth1 && depth1.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.siteSecureURL)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = (depth1 && depth1.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.site)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "/reportReview.account?prodId=";
  if (helper = helpers.productId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.productId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "&reviewId=";
  if (helper = helpers.reviewId) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.reviewId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n              ";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth1 && depth1.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.reportReview)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</a>\n          </p>\n        </div>\n      </div>\n\n    </div>\n  ";
  return buffer;
  }
function program22(depth0,data,depth2) {
  
  var buffer = "", stack1;
  buffer += "\n          <h3>";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth2 && depth2.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.overall)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</h3>\n        ";
  return buffer;
  }

function program24(depth0,data,depth2) {
  
  var buffer = "", stack1;
  buffer += "\n          <div class=\"secondary-score-container\">\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.mixabilityScorePercentage), {hash:{},inverse:self.noop,fn:self.programWithDepth(25, program25, data, depth2),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.tastePercentage), {hash:{},inverse:self.noop,fn:self.programWithDepth(27, program27, data, depth2),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.effectivenessPercentage), {hash:{},inverse:self.noop,fn:self.programWithDepth(29, program29, data, depth2),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n          </div>\n        ";
  return buffer;
  }
function program25(depth0,data,depth3) {
  
  var buffer = "", stack1, helper;
  buffer += "\n              <div class=\"secondary-stars\">\n                <h3>";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth3 && depth3.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.mixabilityTitle)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</h3>\n                <div class=\"rating-holder rating-holder-secondary\">\n                  <div class=\"rating rating-secondary\"></div>\n                  <span class=\"rating-stars rating-stars-secondary\" style=\"width:";
  if (helper = helpers.mixabilityScorePercentage) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.mixabilityScorePercentage); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "%\"></span>\n                </div>\n              </div>\n            ";
  return buffer;
  }

function program27(depth0,data,depth3) {
  
  var buffer = "", stack1, helper;
  buffer += "\n              <div class=\"secondary-stars\">\n                <h3>";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth3 && depth3.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.mixabilityTaste)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</h3>\n                <div class=\"rating-holder rating-holder-secondary\">\n                  <div class=\"rating rating-secondary\"></div>\n                  <span class=\"rating rating-stars-secondary\" style=\"width:";
  if (helper = helpers.tastePercentage) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.tastePercentage); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "%\"></span>\n                </div>\n              </div>\n            ";
  return buffer;
  }

function program29(depth0,data,depth3) {
  
  var buffer = "", stack1, helper;
  buffer += "\n              <div class=\"secondary-stars\">\n                <h3>";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth3 && depth3.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.effectiveness)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</h3>\n                <div class=\"rating-holder rating-holder-secondary\">\n                  <div class=\"rating rating-secondary\"></div>\n                  <span class=\"rating rating-stars-secondary\" style=\"width:";
  if (helper = helpers.effectivenessPercentage) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.effectivenessPercentage); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "%\"></span>\n                </div>\n              </div>\n            ";
  return buffer;
  }

function program31(depth0,data,depth2) {
  
  var buffer = "", stack1;
  buffer += "\n          <span class=\"award-container\">\n            <span class=\"review-gold\"><img src=\"/common/images/reviews/top_reviewer.gif\" alt=\"\" class=\"award-label\" /> ";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth2 && depth2.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.allTimeTop)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>\n          </span>\n        ";
  return buffer;
  }

function program33(depth0,data,depth2,depth3) {
  
  var buffer = "", stack1;
  buffer += "\n          ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.monthlyTop), {hash:{},inverse:self.noop,fn:self.programWithDepth(34, program34, data, depth2),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n          ";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.monthlyTop), {hash:{},inverse:self.noop,fn:self.programWithDepth(36, program36, data, depth2, depth3),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        ";
  return buffer;
  }
function program34(depth0,data,depth3) {
  
  var buffer = "", stack1;
  buffer += "\n            <span class=\"award-container\">\n              <span class=\"review-gold\"><img src=\"/common/images/reviews/top_reviewer.gif\" alt=\"\" class=\"award-label\"> ";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth3 && depth3.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.monthlyTop)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>\n            </span>\n          ";
  return buffer;
  }

function program36(depth0,data,depth3,depth4) {
  
  var buffer = "", stack1;
  buffer += "\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.mostHelpful), {hash:{},inverse:self.noop,fn:self.programWithDepth(37, program37, data, depth3),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            ";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.mostHelpful), {hash:{},inverse:self.noop,fn:self.programWithDepth(39, program39, data, depth4),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n          ";
  return buffer;
  }
function program37(depth0,data,depth4) {
  
  var buffer = "", stack1;
  buffer += "\n              <span class=\"award-container\">\n                <span class=\"review-gold\"><img src=\"/common/images/reviews/top_reviewer.gif\" alt=\"\" class=\"award-label\"> ";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth4 && depth4.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.mostHelpful)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>\n              </span>\n            ";
  return buffer;
  }

function program39(depth0,data,depth5) {
  
  var buffer = "", stack1;
  buffer += "\n              ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.prizeWinner), {hash:{},inverse:self.noop,fn:self.programWithDepth(40, program40, data, depth5),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            ";
  return buffer;
  }
function program40(depth0,data,depth6) {
  
  var buffer = "", stack1;
  buffer += "\n                <span class=\"award-container\">\n                  <span class=\"review-gold\"><img src=\"/common/images/reviews/top_reviewer.gif\" alt=\"\" class=\"award-label\"> ";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth6 && depth6.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.prizeWinner)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>\n                </span>\n              ";
  return buffer;
  }

  buffer += "<div class=\"review-controls\">\n  <div class=\"review-nav-container\">\n\n    <div class=\"sortby-container\">\n        <div class=\"label\">\n          ";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.sortBy)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </div>\n        <div class=\"ddl\">\n          <select name=\"sortingOrder\" id=\"sortingOrder\">\n            <option value=\"created\"\n              ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.createdSelect), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            >";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.createdOnDDL)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</option>\n            <option value=\"rating\"\n              ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.ratingSelect), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            >";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.ratingDDL)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</option>\n            <option value=\"votes\"\n              ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.votesSelect), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            >";
  stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.customerReviews)),stack1 == null || stack1 === false ? stack1 : stack1.votesDDL)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</option>\n          </select>\n        </div>\n      </div>\n\n      ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.showPaging), {hash:{},inverse:self.noop,fn:self.program(3, program3, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n  </div>\n</div>\n\n\n<div class=\"review-content\">\n  <!-- Data all pulled through -->\n  ";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 && depth0.data)),stack1 == null || stack1 === false ? stack1 : stack1.reviewList)),stack1 == null || stack1 === false ? stack1 : stack1.reviews), {hash:{},inverse:self.noop,fn:self.programWithDepth(21, program21, data, depth0, depth1),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>\n";
  return buffer;
  }); });